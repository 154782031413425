import React, { Component } from 'react';

import {Button, CardBody, CardText, Col, Row} from 'reactstrap';

import './FunctionEmptyState.css';
import {OFIcon} from "../OFIcon";

const genSize = (size, order) => ({ size, order });

class FunctionEmptyState extends Component {
    constructor(props) {
      super(props);

      this.state = {
        namespace: props.namespace,
      };
    }

    render() {
      const styleTop = {"marginTop": "20px"}
      const { namespace } = this.props;

      return (
        <CardBody className="FunctionEmptyState">
            <CardText>
              Deploy a function to the {namespace} namespace get started.
            </CardText>
          <Row>
            <Col
              className="FunctionEmptyState__row__col FunctionEmptyState__row__col--img"
               xs={genSize(12, 1)}
               md={genSize(6, 1)}
            >
              <OFIcon />
              <p style={styleTop}>Once you've deployed a function to this namespace, you'll be able to manage it on this page.</p>
              <Button tag="a" href="https://docs.openfaas.com/cli/templates/" target="_blank">
                CREATE A FUNCTION
              </Button>
            </Col>
    
          </Row>
        </CardBody>
      )
    }
}


export {
  FunctionEmptyState,
}
