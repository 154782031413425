import React, { useState } from 'react';
import {
  Badge,
  Nav,
  NavLink,
  Progress,
  Tooltip as StrapTooltip,
} from 'reactstrap';

import './FunctionInvocation.css';

const OPTIONS = {
  '1hr': '60m',
  '24hr': '1440m',
};

const Tooltip = ({ children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <StrapTooltip {...props} isOpen={isOpen} toggle={toggle}>
      {children}
    </StrapTooltip>
  );
};

export function FunctionInvocation({
  functionInvocationData,
  changeFunctionInvocationTimePeriod,
}) {
  const [selected, setSelected] = useState('1hr');

  const navLinkClickHandle = (option) => {
    setSelected(option);
    changeFunctionInvocationTimePeriod(OPTIONS[option]);
  };

  let { success, failure } = functionInvocationData;
  const navLinks = Object.keys(OPTIONS).map((option) => {
    return (
      <NavLink
        key={option}
        href="#"
        active={option === selected}
        onClick={() => navLinkClickHandle(option)}
      >
        {option}
      </NavLink>
    );
  });

  const total = success + failure;
  const successPercent = (success / total) * 100;
  const failurePercent = (failure / total) * 100;

  return (
    <div className="">
      <Nav className="d-flex justify-content-center">
        <span className="d-flex align-items-center mr-4 font-weight-bold">
          Period:
        </span>
        {navLinks}
      </Nav>
      <div>
        <Progress multi={true} className="mt-3 d-flex justify-content-center">
          <Progress bar={true} color="success" value={successPercent} />
          <Progress bar={true} color="danger" value={failurePercent} />
        </Progress>
        <span className="font-weight-bold">{total}</span> invocations
      </div>
      <div className="mt-3 mx-1 row flex-row border">
        <div className="d-flex col-6 flex-column align-items-center border-right p-2">
          <h5 className="mt-1" id="invocation-success-badge">
            <Badge color="success">{success}</Badge>
            <Tooltip placement="top" target="invocation-success-badge">
              Status: 2xx, 3xx
            </Tooltip>
          </h5>
          <span>Success</span>
        </div>
        <div className="d-flex col-6 flex-column align-items-center p-2">
          <h5 className="mt-1" id="invocation-failure-badge">
            <Badge color="danger">{failure}</Badge>
            <Tooltip placement="top" target="invocation-failure-badge">
              Status: 1xx, 4xx, 5xx
            </Tooltip>
          </h5>
          <span>Error</span>
        </div>
      </div>
    </div>
  );
}
