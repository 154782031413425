import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import AceEditor from 'react-ace';
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-monokai";

import { functionsApi } from '../api/functionsApi';
import {
  faExclamationTriangle,
  faSync,
} from '@fortawesome/free-solid-svg-icons';

let mainEditor;

const onEditorLoad = (editor) => {
  mainEditor = editor;

  editor.focus();
  editor.scrollToLine(editor.getSession().getLength());
  editor.navigateLineEnd();
};

const duration = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
};

const PeriodDropDown = ({ defaultValue, periods, onSelect, ...props }) => {
  const [selected, setSelected] = useState(defaultValue || periods[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <InputGroupButtonDropdown  size="xs" isOpen={dropdownOpen} toggle={toggle} {...props}>
      <DropdownToggle outline caret style={{height: '100%', fontSize: '14px' }}>
        {selected.label}
      </DropdownToggle>
      <DropdownMenu>
        {periods.map((period) => (
          <DropdownItem
            onClick={() => {
              setSelected(period);
              onSelect && onSelect(period);
            }}
          >
            {period.label}
          </DropdownItem>
        ))}
      </DropdownMenu>{' '}
    </InputGroupButtonDropdown>
  );
};

export function FunctionLogPage(props) {
  const { namespace, functionName } = props.match.params;
  const functionKey = `${functionName}.${namespace}`;

  const logsStore = JSON.parse(localStorage.getItem('logs_store')) || {};
  const logsState = logsStore[functionKey] || {};

  const periods = [
    {
      label: '15 min',
      duration: 15 * duration.minutes,
    },
    {
      label: '1 hour',
      duration: 1 * duration.hours,
    },
    {
      label: '3 hours',
      duration: 3 * duration.hours,
    },
    {
      label: '6 hours',
      duration: 6 * duration.hours,
    },
    {
      label: '12 hours',
      duration: 12 * duration.hours,
    },
    {
      label: '24 hours',
      duration: 24 * duration.hours,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [log, setLog] = useState('');
  const [fetchError, setFetchError] = useState(false);
  const [period, setPeriod] = useState(logsState.period || periods[0]);

  const loadFunctionLogs = (namespace, functionName) => {
    let since = new Date();
    since.setMilliseconds(since.getMilliseconds() - period.duration);

    functionsApi
      .fetchFunctionLog({ namespace, functionName, since: since.toISOString() })
      .then((res) => {
        setIsLoading(false);
        setLog(res);
        mainEditor.focus();
        mainEditor.scrollToLine(mainEditor.getSession().getLength());
        mainEditor.navigateLineEnd();
      })
      .catch((err) => {
        setIsLoading(false);
        setFetchError(true);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    loadFunctionLogs(namespace, functionName);
  }, []);

  useEffect(() => {
    loadFunctionLogs(namespace, functionName);
    let logsState = {
      period,
    };

    logsStore[functionKey] = logsState;
    localStorage.setItem('logs_store', JSON.stringify(logsStore));
  }, [period]);

  const getPanelBody = (logs, isLoading, fetchError) => {
    const editorOptions = {
      width: '100%',
      height: '600px',
      mode: 'sh',
      theme: 'monokai',
      readOnly: true,
      autoScrollEditorIntoView: true,
      onLoad: onEditorLoad,

      wrapEnabled: true,
      showPrintMargin: false,
      editorProps: {
        $blockScrolling: true,
      },
    };

    if (isLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon="spinner" spin />{' '}
        </div>
      );
    }

    let infoMsg = fetchError
      ? `Can't show logs when the function is scaled to zero`
      : `No recent logs found for ${functionName}, try invoking the function and re-loading this page`;

    if (!fetchError && logs.replace(/\s/g, '').length > 0) {
      return <AceEditor {...editorOptions} value={logs} />;
    } else
      return (
        <Card>
          <CardHeader>
            <FontAwesomeIcon icon={faExclamationTriangle} /> {infoMsg}
          </CardHeader>
          <AceEditor {...editorOptions} value={logs} />
        </Card>
      );
  };

  const reloadPage = (namespace, functionName) => {
    loadFunctionLogs(namespace, functionName);
  };

  return (
    <Card outline color="success">
      <CardHeader className="bg-success color-success flex justify-content-between align-items-center">
        <div>Function logs for {functionName}</div>
        <div className='flex'>
        <PeriodDropDown
          className="mr-2"
          periods={periods}
          defaultValue={period}
          onSelect={setPeriod}
        />
        <Button
          outline
          size="xs"
          title="Re-load logs"
          onClick={() => reloadPage(namespace, functionName)}
          //style={{height: '100%', aspectRatio: '1/1'}}
        >
          <FontAwesomeIcon icon={faSync} />
        </Button>
        </div>
      </CardHeader>
      <CardBody>{getPanelBody(log, isLoading, fetchError)}</CardBody>
    </Card>
  );
}
