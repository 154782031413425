import React, { Component } from 'react';
import { FunctionTable } from '../components/FunctionTable';
import { FunctionEmptyState } from "../components/FunctionEmptyState";
import { functionsApi } from '../api/functionsApi';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
} from 'reactstrap';
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class FunctionsOverviewPage extends Component {
  constructor(props) {
    super(props);

    const { namespace } = props.match.params;

    this.onNamespaceChange = this.onNamespaceChange.bind(this)

    this.state = {
      isLoading: true,
      fns: [],
      authError: false,
      namespace: namespace,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

      functionsApi.fetchFunctions(this.state.namespace)
      .then(res => {
        let functions = [];
        res.forEach( (set) => {
          set.forEach((item) => {
            functions.push(item);
          });
        });
        this.setState({ isLoading: false, fns: functions });
      })
      .catch((e) => {
        if (e.response && e.response.status && e.response.status === 403) {
          this.setState({isLoading: false, fns: [], authError: true})
        } else {
          console.error(e);
        }
      })
  }

  linkBuilder(location) {
      return `/dashboard/${location}`
  }

  renderContentView() {
    const { namespaces, namespace, isLoading, fns, authError } = this.state;

    if (!isLoading && authError) {
      return (
          <Card>
            <CardHeader className="color-failure">
              <FontAwesomeIcon icon={faExclamationTriangle} /> Error: You do not have valid permissions for <span id="namespace">{ namespace }</span>
            </CardHeader>
          </Card>
      )
    }

    if (!isLoading && fns.length === 0) {

      return (
        <FunctionEmptyState namespace={namespace} />
      )
    }

    return (
      <CardBody>
        <CardText>
          Click on a function for more details.
        </CardText>
        <FunctionTable isLoading={isLoading} fns={fns} namespace={namespace} onNamespaceChange={this.onNamespaceChange} />
      </CardBody>
    )
  }

  async onNamespaceChange (ns) {

    this.setState({ namespace: ns})
    this.setState({ isLoading: true })
    this.setState({ fns: [] })
    
  }

  render() {
    const { namespace,fns,isLoading } = this.state;

    if (isLoading && fns.length === 0) {

      functionsApi.fetchFunctions(this.state.namespace)
      .then(res => {
        let functions = [];
        res.forEach( (set) => {
          set.forEach((item) => {
            functions.push(item);
          });
        });
  
        this.setState({ isLoading: false, fns: functions });
      })
      .catch((e) => {
        console.error(e);
        if (e.response.status === 403) {
          this.setState({isLoading: false, fns: [], authError: true})
        } else {
          console.error(e);
        }
      })
    }


    return (
      <Card outline color="success">
        <CardHeader className="bg-success color-success">
              Functions in namespace: {namespace}
        </CardHeader>

        { this.renderContentView() }
      </Card>
    );
  }
}
