import React, { Component } from 'react';
import { NamespaceTable } from '../components/NamespaceTable/NamespaceTable';
import { Redirect } from 'react-router-dom';

import { functionsApi } from '../api/functionsApi';
import { Card, CardHeader, CardBody, CardText } from 'reactstrap';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class NamespacesOverviewPage extends Component {
  constructor(props) {
    super(props);

    const { namespace } = props.match.params;

    this.onNamespaceChange = this.onNamespaceChange.bind(this);

    this.state = {
      isLoading: true,
      namespaces: [],
      authError: false,
      namespace: namespace,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    functionsApi
      .fetchNamespaces()
      .then((res) => {
        if (
          functionsApi.cachedNamespaces &&
          functionsApi.cachedNamespaces.length
        ) {
          this.setState({ namespace: functionsApi.cachedNamespaces[0] });
          this.setState({ namespaces: functionsApi.cachedNamespaces });
        }
        this.setState({ isLoading: false });
        return this.state.namespace;
      })
      .catch((e) => {
        if (e.response.status === 403) {
          this.setState({ isLoading: false, authError: true, namespaces: [] });
        } else {
          console.error(e);
        }
      });
  }
  renderContentView() {
    const { namespaces, isLoading, authError } = this.state;

    if (!isLoading && authError) {
      return (
        <Card>
          <CardHeader className="color-failure">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>
              {' '}
              Error: You do not have valid permissions to list namespaces.
            </span>
          </CardHeader>
        </Card>
      );
    } else if (namespaces && namespaces.length == 1) {
      return <Redirect to={`/functions/${namespaces[0]}`} />;
    }

    return (
      <CardBody>
        <CardText>Click on a namespace to view its functions.</CardText>

        <NamespaceTable
          isLoading={isLoading}
          namespaces={namespaces}
          onNamespaceChange={this.onNamespaceChange}
        />
      </CardBody>
    );
  }

  async onNamespaceChange(ns) {
    this.setState({ isLoading: true });
  }

  render() {
    return (
      <Card outline color="success">
        <CardHeader className="bg-success color-success">
          Namespaces in cluster
        </CardHeader>

        {this.renderContentView()}
      </Card>
    );
  }
}
