import React from 'react';

const NamespaceTableItem = ({ onClick, namespace }) => {

  const handleClick = () => onClick(getNamespacePath(namespace));

  const getNamespacePath = (n) =>  {
    return `/functions/${n}`;
  }

  return (
    <tr onClick={handleClick} className="cursor-pointer">
      <td>
          <a href={getNamespacePath(namespace)} onClick={e => e.stopPropagation()}>{namespace}</a>
      </td>
    </tr>
  )
};

export {
  NamespaceTableItem
};
