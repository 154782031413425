import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardHeader,
  CardBody,
  CardText
} from 'reactstrap';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const UnauthorizedPage = () => (
    <Card className="mt-4" outline color="warning">
        <CardHeader>
          <FontAwesomeIcon icon={faExclamationTriangle} /> Not authorised
        </CardHeader>
        <CardBody>
          <CardText>
            <p>
                You may not be authorised to view this page.
            </p>
            <p>
                Please contact your administrator.
            </p>
            <p>
                You can click <a href='auth/logout'>here</a> to sign out and try again.
            </p>
          </CardText>
        </CardBody>
      </Card>
)