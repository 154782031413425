import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button,
  Form,
  Input,
  FormGroup,
  Col,
  Row,
} from 'reactstrap';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div className="mt-4">
        <Card>
          <CardHeader>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span> You need to authenticate</span>
          </CardHeader>
          <CardBody>
            <CardText>Please sign in to the dashboard.</CardText>
            {window.BASIC_AUTH === "true" ? (
              <Form method="POST" action="/auth/login">
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        name="username"
                        type="text"
                        placeholder="Username"
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Password"
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary">Sign in</Button>
              </Form>
            ) : (
              <Button tag="a" color="primary" href="auth/login">
                Sign in
              </Button>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}
