import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';

import { withRouter } from 'react-router-dom';

import { NamespaceTableItem } from '../NamespaceTableItem';

function renderBody(namespaces, clickHandler) {
  if (!namespaces || namespaces.length === 0) {
    return (
      <tr>
        <td>No namespaces available.</td>
      </tr>
    );
  } else {

    return namespaces.map((namespace) => {
      return (
        <NamespaceTableItem key={namespace} onClick={clickHandler} namespace={namespace} />
      );
    });
  }
}

class NamespaceTableWithoutRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      namespaces: props.namespaces,
    };
  }

  render() {
    const { isLoading, namespaces, history } = this.props;
    const onRowClick = (to) =>  { 
      history.push(to);
    }

    let tableProps = {
      responsive: true,
      className: 'function-table',
    };

    if (namespaces && namespaces.length > 0) {
      tableProps.hover = true;
    }

    const tbody = isLoading ? (
      <tr>
        <td colSpan="8" className="text-center">
          <FontAwesomeIcon icon="spinner" spin />
        </td>
      </tr>
    ) : (
      renderBody(namespaces, onRowClick)
    );

    return (
      <Table {...tableProps} responsive>
        <thead>
        <tr>

          <th>Namespace</th>
          <th />
        </tr>
        </thead>
        <tbody id="items">
          { tbody }
        </tbody>
      </Table>
    );
  }
}

const NamespaceTable = withRouter(NamespaceTableWithoutRouter);

NamespaceTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  namespaces: PropTypes.array.isRequired,
};

export { NamespaceTable };
