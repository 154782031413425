import React from "react";
import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { NavBar } from "./components/NavBar";
import { FunctionsOverviewPage } from "./pages/FunctionsOverviewPage";
import { NamespacesOverviewPage } from "./pages/NamespacesOverviewPage";

import { FunctionLogPage } from "./pages/FunctionLogPage";
import { FunctionDetailPage } from "./pages/FunctionDetailPage";
import { LoginPage } from "./pages/LoginPage";
import { UnauthorizedPage } from "./pages/UnauthorizedPage";
import { FunctionInvokePage } from "./pages/FunctionInvokePage";
import { GraphPage } from "./pages/GraphPage";

import { NotFoundPage } from "./pages/NotFoundPage";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { Footer } from "./components/Footer";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import {
  isAuthenticated,
  isUnauthorized,
  guardIsAuthenticated,
} from "./lib/auth";

const Layout = ({ checkAuth, children }) => {
  // basename is injected from the server
  const basename =
    process.env.NODE_ENV === "production" ? window.BASE_HREF : "/";

  useEffect(() => {
    if (checkAuth) guardIsAuthenticated();
  });

  return (
    <BrowserRouter basename={basename}>
      <div className="container">{children}</div>
    </BrowserRouter>
  );
};

export function App() {
  if (!isAuthenticated()) {
    return (
      <Layout>
        <NavBar />
        <div>
          <LoginPage />
        </div>
      </Layout>
    );
  }

  if (isUnauthorized()) {
    return (
      <Layout checkAuth>
        <NavBar />
        <div>
          <UnauthorizedPage />
        </div>
      </Layout>
    );
  }

  return (
    <Layout checkAuth>
      <NavBar />
      <Breadcrumbs />
      <div>
        <Switch>
          <Route exact path="/functions/" component={NamespacesOverviewPage} />
          <Route
            exact
            path="/functions/:namespace"
            component={FunctionsOverviewPage}
          />
          <Route
            exact
            path="/functions/:namespace/:functionName"
            component={FunctionDetailPage}
          />
          <Route
            path="/functions/:namespace/:functionName/logs"
            component={FunctionLogPage}
          />
          <Route
            path="/functions/:namespace/:functionName/invoke"
            component={FunctionInvokePage}
          />
          <Route
            path="/functions/:namespace/:functionName/graphs"
            component={GraphPage}
          />
          <Route exact path="/" component={NamespacesOverviewPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
      <Footer />
    </Layout>
  );
}
