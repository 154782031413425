import React from 'react';

import { Card, CardHeader, CardBody } from 'reactstrap';
import { InvocationPanel } from '../components/InvocationPanel';

export function FunctionInvokePage(props) {
    const { namespace, functionName } = props.match.params;

    return (
        <Card outline color="success">
        <CardHeader className="bg-success color-success d-flex align-items-center justify-content-between">
          <div>Invoke {functionName}</div>
        </CardHeader>
        <CardBody>
            <InvocationPanel fn={functionName} namespace={namespace} />
        </CardBody>
      </Card>
    )
}