import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Form,
  Input,
  InputGroup,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

class GetGitLabelsModal extends Component {
  state = {
    valid: false,
  };

  constructor() {
    super();

    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleCopyClick(e) {
    e.preventDefault();

    this.input.select();

    document.execCommand('copy');

    this.setState(
      {
        valid: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            valid: false,
          });
        }, 1500);
      }
    );
  }

  closeModal() {
    this.setState({ active: false });
  }

  render() {
    const badgeMd = `figlet:
  annotations:
    com.openfaas.git-repo-url: "https://github.com/alexellis/figlet"
  labels:
    com.openfaas.git-owner: "alexellis"
    com.openfaas.git-repo: "figlet"
    com.openfaas.git-branch: "master"
    com.openfaas.git-sha: "665d9597547d8e0425630ba2dbb73c2951a61ce2"`;

    return (
      <Modal
        size='lg'
        isOpen={this.props.state}
        toggle={this.props.closeModal}
        className={this.props.className}
      >
        <ModalHeader toggle={this.props.closeModal}>Set metadata</ModalHeader>
        <ModalBody>
          <p>
            Add the following labels and annotations to your function during
            deployment.
          </p>

          <Form>
            <InputGroup>
              <Input
                className="text-monospace"
                readOnly
                innerRef={(node) => {
                  this.input = node;
                }}
                type="textarea"
                valid={this.state.valid}
                value={badgeMd}
                bsSize="sm"
                rows="8"
              />
              <FormFeedback valid tooltip>
                Link copied
              </FormFeedback>
            </InputGroup>
            <Button
              onClick={this.handleCopyClick}
              className="radius-right-5 mt-2"
            >
              <span>Copy</span>
              <FontAwesomeIcon className="ml-2" icon={faCopy} />
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export { GetGitLabelsModal };
