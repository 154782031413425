import React, { Component, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Redirect } from 'react-router-dom';

import { functionsApi } from "../api/functionsApi";
import { FunctionDetailSummary } from "../components/FunctionDetailSummary";
import { GetGitLabelsModal } from "../components/GetGitLabelsModal";
import { Button } from "reactstrap";
import { faTrashAlt, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function DeleteFunctionModal({
  namespace,
  fnName,
  isOpen,
  toggle,
  headerText,
}) {
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState(null);

  const deleteFunction = async () => {
    setDeleting(true);

    try {
      await functionsApi.deleteFunction(namespace, fnName)
      setDeleted(true)
    } catch (err) {
      if (err.response.status == 403 || error.response.status == 401) {
        setError(`You do not have permissions to delete: ${fnName}.${namespace}.`)
      } else {
        console.error(`Failed to delete function: ${fnName}.${namespace}., err: ${err}`)
        setError(`Failed to delete function: ${fnName}.${namespace}.`)
      }
    }

    setDeleting(false)
  }

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Delete function</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to delete <b>{fnName}.{namespace}</b>?</p>
        { deleted && <Redirect to={`/functions/${namespace}`} />}
        <Button
          color="danger"
          size="sm"
          disabled={deleting}
          onClick={() => deleteFunction()}
        >
          Delete this function
        </Button>
        { error && <div className="color-failure mt-3">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span>
            {' '}
            Error: {error}
          </span>
        </div>}
      </ModalBody>
    </Modal>
  );
}

export class FunctionDetailPage extends Component {
  constructor(props) {
    super(props);
    const { namespace, functionName } = props.match.params;

    this.handleShowBadgeModal = this.handleShowBadgeModal.bind(this);
    this.handleCloseBadgeModal = this.handleCloseBadgeModal.bind(this);
    this.toggleDeleteFnModal = this.toggleDeleteFnModal.bind(this);

    this.state = {
      isLoading: true,
      fn: null,
      fnDetail: {},
      functionInvocationData: null,
      namespace: namespace,
      functionName: functionName,
      showBadgeModal: false,
      showDeleteFnModal: false,
    };
  }

  changeFunctionInvocationTimePeriod = async (timePeriod) => {
    const { namespace, functionName } = this.state;

    let params = {
      namespace,
      functionName,
      timePeriod,
    };

    try {
      let res = await functionsApi.fetchFunctionInvocation(params);
      this.setState({ functionInvocationData: res });
    } catch (err) {
      console.error("Error fetching function invocation", params, err);
    }

    params = {
      namespace,
      functionName,
    };

    try {
      let res = await functionsApi.fetchFunctionDetails(params);
      this.setState({ fnDetail: res });
    } catch (err) {
      console.error("Error fetching function details", params, err);
    }
  };

  componentDidMount() {
    const { namespace, functionName } = this.state;

    this.setState({ isLoading: true });

    functionsApi
      .fetchFunction(namespace, functionName)
      .then((res) => {
        this.setState({ isLoading: false, fn: res });
      })
      .catch((err) => console.error("Error fetching function", err));

    this.changeFunctionInvocationTimePeriod("60m");
  }

  handleShowBadgeModal() {
    this.setState({ showBadgeModal: true });
  }

  handleCloseBadgeModal() {
    this.setState({ showBadgeModal: false });
  }

  toggleDeleteFnModal() {
    this.setState({ showDeleteFnModal: !this.state.showDeleteFnModal });
  }

  render() {
    const { isLoading, fn, functionInvocationData, fnDetail } = this.state;

    let panelBody = (
      <div style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon="spinner" spin />{" "}
      </div>
    );

    if (!isLoading) {
      panelBody = (
        <div>
          <FunctionDetailSummary
            fn={fn}
            fnDetail={fnDetail}
            changeFunctionInvocationTimePeriod={
              this.changeFunctionInvocationTimePeriod
            }
            functionInvocationData={functionInvocationData}
            handleShowBadgeModal={this.handleShowBadgeModal}
          />
        </div>
      );
    }

    return (
      <>
        <Card outline color="success">
          <CardHeader className="bg-success color-success d-flex align-items-center justify-content-between">
            <div>Function Overview</div>
            <Button
              outline
              color="secondary"
              size="xs"
              onClick={this.toggleDeleteFnModal}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
              <span>Delete</span>
            </Button>
          </CardHeader>
          <CardBody>{panelBody}</CardBody>
          <GetGitLabelsModal
            state={this.state.showBadgeModal}
            closeModal={this.handleCloseBadgeModal}
          />
        </Card>
        {!isLoading && (
          <DeleteFunctionModal
            namespace={fn.namespace}
            fnName={fn.name}
            isOpen={this.state.showDeleteFnModal}
            toggle={this.toggleDeleteFnModal}
          />
        )}
      </>
    );
  }
}
