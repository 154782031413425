import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { useLocalStorage } from "../hooks/useLocalStorage";

const PeriodSelector = ({ values, defaultValue, onSelect, className }) => {
  const [selected, setSelected] = useState(defaultValue || values[0]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onSelect && onSelect(selected);
  }, [selected]);

  return (
    <ButtonDropdown
      size="xs"
      isOpen={open}
      toggle={() => setOpen((prevState) => !prevState)}
      className={className}
    >
      <DropdownToggle
        outline
        caret
        style={{ height: "100%", fontSize: "14px" }}
      >
        {selected.displayValue}
      </DropdownToggle>
      <DropdownMenu>
        {values.map((value) => (
          <DropdownItem
            key={value.displayValue}
            onClick={() => setSelected(value)}
          >
            {value.displayValue}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const periods = [
  { value: 5, unit: "minutes", displayValue: "5 min" },
  { value: 10, unit: "minutes", displayValue: "10 min" },
  { value: 1, unit: "hours", displayValue: "1 hour" },
  { value: 6, unit: "hours", displayValue: "6 hours" },
  { value: 24, unit: "hours", displayValue: "24 hours" },
];

function getTimeRange(period) {
  // Get current date and time as end time
  const endTime = new Date();
  const startTime = new Date(endTime);

  const { value, unit } = period;

  // TODO: HV, improve resolution calculation based on the time range.
  let step = "10s";

  switch (unit) {
    case "minutes":
      startTime.setMinutes(startTime.getMinutes() - value);
      step = "10s";
      break;
    case "hours":
      startTime.setHours(startTime.getHours() - value);
      step = "60s";
      break;
    default:
      throw new Error(`Unknown time unit: ${unit}`);
  }

  return [startTime, endTime, step];
}

const formatTooltipTime = (date) => {
  // Get the year, month, day, hours, minutes, and seconds
  const year = date.getFullYear().toString();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Return formatted date and time
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

const CustomTooltip = ({ active, payload }) => {
  const ulStyle = {
    paddingLeft: 0, // Removes the left padding
    listStyleType: "none", // Removes the default list dots
  };

  if (active && payload && payload.length) {
    const time = payload[0]?.payload?.time
    const label = time && formatTooltipTime(time);
    
    return (
      <div
        style={{
          padding: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
        }}
      >
        <p>{label}</p>
        <ul style={ulStyle}>
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name} - ${entry.value.toFixed(3)}`}s
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

export function GraphPage(props) {
  const { namespace, functionName } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [period, setPeriod] = useLocalStorage(periods[0]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [startTime, endTime, step] = getTimeRange(period);

      const queryParams = new URLSearchParams({
        function: functionName,
        namespace: namespace,
        start: startTime.getTime() / 1000,
        end: endTime.getTime() / 1000,
        step,
      }).toString();

      const response = await fetch(`/api/metrics/latency?${queryParams}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result.status === "success") {
        const chartData = mapPrometheusData(result);
        setData(chartData);
      } else {
        console.error("Error fetching data:", result.error);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [period]);

  const formatAxisTime = (period, time) => {
    switch (period.unit) {
      case "minutes":
        return time.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
      case "hours":
        return time.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: false,
        });
    }
  };

  return (
    <Card outline color="success">
      <CardHeader className="bg-success color-success flex align-items-center justify-content-between">
        <div>Metrics from your function</div>
        <div className="flex">
          <PeriodSelector
            className="mr-2"
            values={periods}
            defaultValue={period}
            onSelect={(val) => setPeriod(val)}
          />
          <Button
            outline
            size="xs"
            title="reload graphs"
            onClick={() => fetchData()}
            // style={{height: '100%', aspectRatio: '1/1'}}
          >
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <p className="is-bold">Latency by status code</p>
        {loading && !data && (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              className="text-secondary"
              size="2x"
              icon="spinner"
              spin
            />
          </div>
        )}
        {data?.data?.length ? (
          <div style={{ position: "relative", textAlign: "center" }}>
            {loading && (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    zIndex: 10,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                >
                  <FontAwesomeIcon
                    className="text-secondary"
                    size="2x"
                    icon="spinner"
                    spin
                  />
                </div>
              </div>
            )}
            <div style={{ position: "relative" }}>
              <ResponsiveContainer width="100%" height={350}>
                <AreaChart
                  data={data.data}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={({ time }) => formatAxisTime(period, time)} />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  {data.metrics.map((metric, metricIndex) => (
                    <Area
                      key={metricIndex}
                      type="monotone"
                      dataKey={(data) => data.values[metricIndex]}
                      name={`${metric.code}`}
                      stroke={deterministicColorFromSet(metric.code)}
                      strokeWidth={2}
                      fill={deterministicColorFromSet(metric.code)}
                      fillOpacity={0.3}
                      isAnimationActive={false}
                    />
                  ))}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <p>No data available</p>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

function mapPrometheusData(result) {
  const resultType = result.data.resultType;
  const mapValues = new Map();
  const metrics = result.data.result.map((result) => result.metric);
  result.data.result.forEach((result, index) => {
    result.values.forEach(([timestamp, rawValue]) => {
      let value = parseFloat(rawValue);
      if (isNaN(value)) {
        value = null;
      }

      let v = mapValues.get(timestamp);
      if (v) {
        v[index] = value;
      } else {
        v = [];
        v[index] = value;
        mapValues.set(timestamp, v);
      }
    });
  });

  const data = [];
  for (const [timestamp, values] of mapValues) {
    data.push({
      time: new Date(timestamp * 1000),
      values,
    });
  }

  return { metrics, data };
}

function deterministicColorFromSet(key) {
  // Predefined set of contrast-safe colors
  const colorSet = [
    "#7EB26D", // Green
    "#EAB839", // Yellow
    "#6ED0E0", // Cyan
    "#EF843C", // Orange
    "#E24D42", // Red
    "#1F78C1", // Blue
    "#BA43A9", // Magenta
    "#705DA0", // Purple
    "#508642", // Dark Green
  ];

  // Simple hash function to generate a numeric index
  let hash = 0;
  for (let i = 0; i < key.length; i++) {
    hash = (hash << 5) - hash + key.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }

  // Map hash to the color set
  const index = Math.abs(hash) % colorSet.length;

  return colorSet[index];
}
