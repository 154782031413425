import React from 'react';

const OFIconOneColor = ({ width, height, fill, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-inline--fa fa-w-18 ${className}`}
    version="1.1"
    viewBox="0 0 101.477 101.478"
    data-name="OpenFaaS Logo"
    id="openfaas_logo"
  >
    <path
      id="path4261"
      d="m 58.17,85.221 c -1.153,-2.729 -2.49,-5.886 -3.639,-8.918 -2.853,-7.458 8.188,-8.611 8.188,-8.611 0,0 23.654,-1.213 25.044,-28.566 0,0 -7.581,7.7 -14.193,6.732 -6.612,-0.968 -16.07,-5.459 -23.707,8.37 -7.7,-13.829 -17.1,-9.281 -23.714,-8.371 -6.612,0.97 -14.193,-6.732 -14.193,-6.732 1.4,27.413 25.051,28.566 25.051,28.566 0,0 10.187,1.153 8.188,8.611 a 82.865,82.865 0 0 1 -2.853,8.794 l 2.063,3.763 2.789,2.183 2.669,0.726 h 1.76 l 3.336,-2.486 2.426,-3.276 z m 0,0"
      className="cls-1"
      style={{ fill }}
    />
    <path
      id="path4263"
      d="M 73.393,45.857 C 66.781,44.887 57.32,40.398 49.679,54.228 a 23.342,23.342 0 0 0 -3.46,-4.915 c 0.427,10.071 2.123,32.815 8.128,40.333 l 0.363,-0.3 2.426,-3.273 0.85,-0.849 c -1.153,-2.729 -2.486,-5.886 -3.639,-8.918 -2.849,-7.458 8.188,-8.611 8.188,-8.611 0,0 23.654,-1.213 25.051,-28.566 0,-0.061 -7.522,7.641 -14.193,6.732 z m 0,0"
      className="cls-1"
      style={{ fill }}
    />
    <path
      id="path4265"
      d="M 58.171,86.937 A 1.776,1.776 0 0 1 56.546,85.881 C 55.379,83.255 54.108,80.14 52.869,76.872 a 6.816,6.816 0 0 1 0.338,-6.25 c 2.413,-3.93 8.626,-4.686 9.327,-4.759 0.936,-0.052 19.71,-1.307 23.021,-22.589 -3.077,2.281 -7.761,4.935 -12.241,4.281 -1,-0.138 -2.043,-0.351 -3.144,-0.577 -6.293,-1.29 -12.8,-2.626 -18.746,8.028 a 1.775,1.775 0 0 1 -0.834,0.779 1.539,1.539 0 0 1 -0.735,0.158 1.777,1.777 0 0 1 -1.563,-0.937 c -5.944,-10.653 -12.45,-9.32 -18.744,-8.028 -1.1,0.226 -2.143,0.439 -3.162,0.58 -4.474,0.656 -9.149,-2 -12.224,-4.281 3.314,21.283 22.083,22.535 22.93,22.58 0.035,0 0.074,0 0.109,0.009 0.253,0.028 6.216,0.744 8.82,4.566 a 7.413,7.413 0 0 1 0.885,6.271 85.324,85.324 0 0 1 -2.915,8.982 1.7780678,1.7780678 0 0 1 -3.31,-1.3 81.813,81.813 0 0 0 2.791,-8.606 3.876,3.876 0 0 0 -0.389,-3.351 C 41.478,70.072 37.191,69.445 36.826,69.396 35.178,69.272 11.594,66.975 10.176,39.151 a 1.777,1.777 0 0 1 3.04,-1.338 c 1.939,1.963 7.988,6.907 12.67,6.221 0.919,-0.126 1.9,-0.329 2.948,-0.542 6.025,-1.236 14.12,-2.9 21.024,7.275 6.908,-10.172 15,-8.512 21.025,-7.275 1.043,0.214 2.027,0.416 2.93,0.54 4.711,0.692 10.751,-4.254 12.685,-6.217 a 1.777,1.777 0 0 1 3.041,1.337 c -1.417,27.9 -25.134,30.133 -26.66,30.246 -1.446,0.161 -5.382,1.018 -6.647,3.085 a 3.407,3.407 0 0 0 -0.043,3.122 c 1.218,3.212 2.463,6.264 3.6,8.831 a 1.778,1.778 0 0 1 -1.623,2.5 z"
      className="cls-1"
      style={{ fill }}
    />
    <path
      id="path4267"
      d="m 2.371,89.83 c 0,0 10.007,-4.732 18.8,-9.825 0.423,-0.243 5.882,0 5.882,0 h 4.792 c 0.423,0 6.732,3.819 6.732,3.819 0,0 6.552,3.033 7.7,3.336 1.148,0.303 11.1,-1.09 11.1,-1.09 l 7.338,-4.125 5.822,-1.94 11.4,1.94 c 0,0 25.717,6.185 15.65,9.7 -0.607,0.183 -5.219,5.4 -5.762,5.822 -0.487,0.426 -15.227,3.883 -15.227,3.883 H 46.949 L 10.255,98.5 Z m 0,0"
      className="cls-1"
      style={{ fill }}
    />
    <path
      id="path4269"
      d="m 98.263,89.424 c -6.465,0 -9.855,-2.18 -12.848,-4.1 -2.831,-1.82 -5.506,-3.539 -10.93,-3.539 -5.424,0 -8.1,1.719 -10.926,3.538 -2.992,1.923 -6.382,4.1 -12.848,4.1 -6.466,0 -9.856,-2.18 -12.848,-4.1 -2.831,-1.819 -5.5,-3.538 -10.926,-3.538 -5.426,0 -8.1,1.719 -10.931,3.539 -2.992,1.923 -6.382,4.1 -12.847,4.1 a 1.7775,1.7775 0 0 1 0,-3.555 c 5.421,0 8.095,-1.718 10.926,-3.538 2.992,-1.923 6.384,-4.1 12.852,-4.1 6.468,0 9.856,2.18 12.848,4.1 2.831,1.819 5.5,3.538 10.926,3.538 5.426,0 8.1,-1.719 10.926,-3.539 2.992,-1.923 6.382,-4.1 12.848,-4.1 6.466,0 9.86,2.18 12.852,4.1 2.831,1.82 5.5,3.538 10.926,3.538 a 1.7775,1.7775 0 1 1 0,3.555 z"
      className="cls-1"
      style={{ fill }}
    />
    <path
      id="path4271"
      d="m 37.989,18.813 -2.893,18.712 20.689,3.2 2.893,-18.712 z m 4.59,14.311 -1.816,-0.247 0.969,-7.158 1.817,0.246 z m 4.369,0.544 -1.816,-0.248 0.97,-7.1 1.816,0.248 z m 4.306,0.606 -1.816,-0.248 0.969,-7.1 1.816,0.248 z"
      className="cls-1"
      style={{ fill }}
    />
    <path
      id="path4273"
      d="m 17.046,18.237 5.469,18.13 17.74,-5.351 0.941,-5.211 -4.106,-13.611 z m 10.317,10.929 -1.746,0.563 -2.214,-6.875 1.746,-0.563 z m 4.176,-1.395 -1.745,0.56 -2.189,-6.819 1.745,-0.56 z m 4.146,-1.312 -1.745,0.56 -2.189,-6.818 1.745,-0.56 z"
      className="cls-1"
      style={{ fill }}
    />
    <path
      id="path4275"
      d="m 81.463,15.631 -20.881,1.472 1.331,18.884 20.882,-1.468 z m -14.872,14.017 -0.363,-7.155 1.831,-0.093 0.363,7.155 z m 4.43,-0.232 -0.423,-7.158 1.83,-0.108 0.423,7.158 z m 4.365,-0.243 -0.423,-7.158 1.83,-0.108 0.423,7.158 z"
      className="cls-1"
      style={{ fill }}
    />
    <path
      id="path4277"
      d="M 83.276,0 H 18.2 A 18.221,18.221 0 0 0 0,18.2 v 65.078 a 18.221,18.221 0 0 0 18.2,18.2 h 65.077 a 18.221,18.221 0 0 0 18.2,-18.2 V 18.2 A 18.221,18.221 0 0 0 83.276,0 Z M 98.194,83.278 A 14.935,14.935 0 0 1 83.276,98.195 H 18.2 A 14.935,14.935 0 0 1 3.282,83.278 V 18.2 A 14.935,14.935 0 0 1 18.2,3.283 H 83.277 A 14.935,14.935 0 0 1 98.195,18.2 Z"
      className="cls-1"
      style={{ fill }}
    />
    <rect
      style={{ fill }}
      y="19.644663"
      x="22"
      height="10.5"
      width="15.333333"
      id="rect4361"
    />
    <rect
      style={{ fill }}
      y="23.311331"
      x="38.833332"
      height="12.333333"
      width="14.833333"
      id="rect4363"
    />
    <rect
      style={{ fill }}
      y="20.311331"
      x="63.5"
      height="11"
      width="16.166666"
      id="rect4365"
    />
  </svg>
);

const OFIconMultiColor = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 150 151" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M123.347 147.97H27.168c-13.446 0-24.474-11.054-24.474-24.532V27.03c0-13.478 11.028-24.532 24.474-24.532h96.18c13.445 0 24.473 11.054 24.473 24.532v96.408c0 13.478-11.028 24.533-24.474 24.533z"
        fill="#E6E7E8"/>
      <path
        d="M86.242 126.317c-1.705-4.043-3.68-8.719-5.379-13.212-4.216-11.048 12.102-12.756 12.102-12.756s34.959-1.797 37.017-42.32c0 0-11.205 11.41-20.977 9.973-9.766-1.437-23.754-8.087-35.048 12.4-11.381-20.487-25.275-13.748-35.047-12.4-9.772 1.436-20.977-9.973-20.977-9.973 2.064 40.611 37.023 42.32 37.023 42.32s15.056 1.708 12.102 12.756c-1.345 5.036-2.778 9.346-4.217 13.029l3.049 5.574 4.122 3.234 3.945 1.076h2.601l4.93-3.683 3.586-4.853 1.168-1.165z"
        fill="#12A7DD"/>
      <path
        d="M108.74 68.002c-9.772-1.437-23.754-8.087-35.048 12.4-1.616-2.966-3.32-5.39-5.113-7.281.631 14.92 3.138 48.614 12.013 59.752l.537-.45 3.585-4.848 1.256-1.258c-1.704-4.043-3.674-8.719-5.378-13.212-4.21-11.048 12.101-12.756 12.101-12.756s34.96-1.797 37.024-42.32c0-.09-11.117 11.32-20.977 9.973z"
        fill="#37B7EA"/>
      <path
        d="M73.957 80.31C62.576 59.824 48.682 66.563 38.91 67.911c-9.772 1.436-20.977-9.973-20.977-9.973 2.064 40.611 37.023 42.32 37.023 42.32s15.056 1.708 12.102 12.758c-1.345 5.03-2.778 9.344-4.217 13.028M73.957 80.31c11.388-20.484 25.282-13.745 35.048-12.397 9.772 1.436 20.977-9.973 20.977-9.973-2.058 40.611-37.017 42.32-37.017 42.32s-16.318 1.708-12.102 12.758c1.699 4.492 3.586 9.167 5.379 13.21"
        stroke="#3A4E55" strokeWidth="3.554" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3.774 133.145s14.79-7.011 27.788-14.555c.625-.36 8.692 0 8.692 0h7.083c.625 0 9.949 5.658 9.949 5.658s9.683 4.493 11.382 4.942c1.704.45 16.406-1.615 16.406-1.615l10.845-6.11 8.604-2.875 16.855 2.874s38.008 9.163 23.129 14.377c-.896.27-7.714 7.998-8.516 8.624-.72.632-22.504 5.752-22.504 5.752H69.658l-54.231-4.222-11.653-12.85z"
        fill="#95D8F2"/>
      <path fill="#37B7EA" d="M27.448 27.699l26.233-8.026 7.144 23.465-26.238 8.02z"/>
      <path stroke="#3A4E55" strokeWidth="1.834" d="M27.448 27.7l26.233-8.027 7.144 23.465-26.238 8.02z"/>
      <path d="M79.872 32.96s-7.17 23.45-22.675 21.026l24.02 3.953 3.586-24.26-4.93-.72z" fill="#E0E1E2"/>
      <path fill="#BCBEC0" d="M27.448 27.699l26.233-8.026 7.144 23.465-26.238 8.02z"/>
      <path stroke="#3A4E55" strokeWidth="2.376" d="M27.448 27.7l26.233-8.027 7.144 23.465-26.238 8.02z"/>
      <path fill="#BCBEC0" d="M91.685 27.034l27.359-1.933 1.717 24.471L93.403 51.5z"/>
      <path stroke="#3A4E55" strokeWidth="2.376" d="M91.686 27.035l27.356-1.934 1.72 24.471L93.402 51.5z"/>
      <path fill="#BCBEC0" d="M57.883 29.945l27.107 4.203-3.74 24.24-27.106-4.202z"/>
      <path stroke="#3A4E55" strokeWidth="2.376" d="M57.883 29.945l27.107 4.203-3.74 24.24-27.106-4.2z"/>
      <path
        d="M145.492 129.91c-17.568 0-17.568-11.32-35.142-11.32-17.568 0-17.568 11.32-35.136 11.32s-17.568-11.32-35.136-11.32c-17.575 0-17.575 11.32-35.143 11.32"
        stroke="#3A4E55" strokeWidth="3.554" strokeLinecap="round" strokeLinejoin="round"/>
      <g stroke="#3A4E55">
        <path
          d="M99.418 33.341l.539 10.608m5.94-10.968l.627 10.612m5.845-10.972l.627 10.61m-49.908-4.856L61.65 48.988m7.916-9.713l-1.438 10.518m7.821-9.62l-1.437 10.52M35.66 32.17l2.882 10.25m3.324-12.048l2.882 10.25m3.413-11.96l2.881 10.252"
          strokeWidth="1.834"/>
        <path
          d="M123.337 148.07H26.851c-13.488 0-24.552-11.06-24.552-24.547V27.047C2.3 13.56 13.363 2.5 26.851 2.5h96.486c13.489 0 24.552 11.06 24.552 24.547v96.476c0 13.487-11.063 24.547-24.552 24.547z"
          strokeWidth="3.283" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </g>
  </svg>
);

const OFIcon = ({ oneColor, ...otherProps }) => {
  if (oneColor) {
    return (
      <OFIconOneColor {...otherProps} />
    );
  }

  return (
    <OFIconMultiColor {...otherProps } />
  );
};

OFIcon.defaultProps = {
  width: 150,
  height: 150,
  fill: 'currentColor',
  className: '',
  oneColor: false,
};

export {
  OFIcon
};