import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getToken = () => cookies.get('openfaas_dashboard');

export function isAuthenticated() {
  let token = getToken();
  return token ? true : false;
}

export function isUnauthorized() {
  let token = getToken();
  return token === 'unauthorized' ? true : false;
}

// Periodically check if the authentication cookie has expired
// and reload the page is this is the case.
export function guardIsAuthenticated() {
  setInterval(() => {
    if (!isAuthenticated()) {
      window.location.reload();
    }
  }, 60 * 1000);
}
