import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserSecret,
  faTerminal,
  faPlayCircle,
  faChartArea,
} from "@fortawesome/free-solid-svg-icons";
import { ReplicasProgress } from "../ReplicasProgress";

const genLogPath = ({ name, namespace }) =>
  `/functions/${namespace}/${name}/logs/`;

const genInvokePath = ({ name, namespace }) =>
  `/functions/${namespace}/${name}/invoke`;

const genGraphsPath = ({ name, namespace }) =>
  `/functions/${namespace}/${name}/graphs`;

const genFnDetailPath = ({ name, namespace }) =>
  `/functions/${namespace}/${name}`;

const genRepoUrl = ({ gitRepoURL, gitBranch }) => {
  if (gitBranch === "") {
    return `${gitRepoURL}/commits/master`;
  }
  return `${gitRepoURL}/commits/${gitBranch}`;
};

const FunctionTableItem = ({ onClick, fn }) => {
  const {
    name,
    gitRepo,
    shortSha,
    gitPrivate,
    sinceDuration,
    invocationCount,
  } = fn;

  const repoUrl = genRepoUrl(fn);
  const logPath = genLogPath(fn);
  const invokePath = genInvokePath(fn);
  const graphsPath = genGraphsPath(fn);
  const fnDetailPath = genFnDetailPath(fn);

  const handleClick = () => onClick(fnDetailPath);

  return (
    <tr onClick={handleClick} className="cursor-pointer">
      <td>{name}</td>
      <td>
        <div className="flex">
          <Button
            outline
            size="xs"
            title="Invoke"
            to={invokePath}
            onClick={(e) => e.stopPropagation()}
            tag={Link}
            className="mr-2"
          >
            <FontAwesomeIcon icon={faPlayCircle} />
          </Button>
          <Button
            outline
            size="xs"
            title="Logs"
            to={logPath}
            onClick={(e) => e.stopPropagation()}
            tag={Link}
            className="mr-2"
          >
            <FontAwesomeIcon icon={faTerminal} />
          </Button>
          <Button
            outline
            size="xs"
            title="Graphs"
            to={graphsPath}
            onClick={(e) => e.stopPropagation()}
            tag={Link}
          >
            <FontAwesomeIcon icon={faChartArea} />
          </Button>
        </div>
      </td>
      <td className={"d-none d-sm-table-cell"}>
        {invocationCount ? invocationCount : "0"}
      </td>
      <td className={"d-none d-sm-table-cell"}>
        <ReplicasProgress scaling={fn.scale.type !== "disabled"} fn={fn} />
      </td>
      <td>{sinceDuration}</td>
      <td className={"d-none d-sm-table-cell"}>
        <div className="d-flex justify-content-between align-items-center">
          <a href={repoUrl} onClick={(e) => e.stopPropagation()}>
            {gitRepo}
          </a>
          {gitPrivate && <FontAwesomeIcon icon={faUserSecret} />}
        </div>
      </td>
      <td className={"d-none d-sm-table-cell"}>{shortSha}</td>
    </tr>
  );
};

export { FunctionTableItem };
