import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';

import { withRouter } from 'react-router-dom';

import { FunctionTableItem } from '../FunctionTableItem';

import './FunctionTable.css'

function renderBody(fns, namespace, clickHandler) {
  if (fns.length === 0) {
    return (
      <tr>
        <td>No functions available.</td>
      </tr>
    );
  } else {
    return fns.sort((a,b) => { 
      if( a.createdAt < b.createdAt){
        return 1
      } else if(a.createdAt > b.createdAt){
        return -1
      }else {
        return 0
      } }).map((fn) => {
      return (
        <FunctionTableItem key={fn.shortSha + fn.name} fn={fn} namespace={namespace} onClick={clickHandler} />
      );
    });
  }
}

class FunctionTableWithoutRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      namespace: props.namespace,
    };

  }

  render() {
    const { isLoading, fns, namespace, history } = this.props;
    const onRowClick = to => history.push(to);

    let tableProps = {
      responsive: true,
      className: 'function-table',
    };

    if (fns && fns.length > 0) {
      tableProps.hover = true;
    }

    const tbody = isLoading ? (
      <tr>
        <td colSpan="8" className="text-center">
          <FontAwesomeIcon icon="spinner" spin />
        </td>
      </tr>
    ) : (
      renderBody(fns, namespace, onRowClick)
    );

    return (
      <Table {...tableProps} responsive>
        <thead>
        <tr>
          <th>Name</th>
          <th style={{ width: '42px' }} className={"d-none d-sm-table-cell"} scope={"col"}>Actions</th>
          <th className={"d-none d-sm-table-cell"} scope={"col"}>Invocations</th>
          <th className={"d-none d-sm-table-cell"} scope={"col"}>Replicas</th>
          <th>Deployed</th>
          <th className={"d-none d-sm-table-cell"} scope={"col"}>Repository</th>
          <th className={"d-none d-sm-table-cell"} scope={"col"}>SHA</th>
        </tr>
        </thead>
        <tbody id="items">
          { tbody }
        </tbody>
      </Table>
    );
  }
}

const FunctionTable = withRouter(FunctionTableWithoutRouter);

FunctionTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fns: PropTypes.array.isRequired,
  namespace: PropTypes.string.isRequired,
  onNamespaceChange: PropTypes.func.isRequired,
};

export { FunctionTable };
