import React, { Children } from 'react';
import { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupButtonDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTerminal,
} from '@fortawesome/free-solid-svg-icons';
import { functionsApi } from '../../api/functionsApi';

import './InvocationPanel.scss';

import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';

function isStatusSuccess(code) {
  return code >= 200 && code <= 299;
}

const getEditorMode = (contentType) => {
  contentType = contentType || '';
  let parts = contentType.split(';');
  let type = parts[0].toLowerCase();

  switch (type) {
    case 'application/json':
      return 'json';
    case 'text/plain':
      return 'text';
    default:
      return 'text';
  }
};

const LogsButton = ({to}) => (
  <Button outline color="secondary" size="xs" tag={Link} to={to}>
    <FontAwesomeIcon icon={faTerminal} className="mr-2" />
    <span>Logs</span>
  </Button>
);

const ResponseInfo = ({ statusCode, statusText, duration, size }) => {
  return (
    <div className="pb-3 pt-3 flex flex-col">
      <div className={`mr-2 `}>
        Status:{' '}
        <span
          className={
            isStatusSuccess(statusCode) ? 'text-success' : 'text-danger'
          }
        >
          {statusCode} - {statusText}
        </span>
      </div>
      <div className="mr-2">
        Time:{' '}
        <span
          className={
            isStatusSuccess(statusCode) ? 'text-success' : 'text-danger'
          }
        >
          {Math.round(duration)} ms
        </span>
      </div>
    </div>
  );
};

const KeyValueEditor = ({ placeholder, defaultValue, onChange, ...props }) => {
  const placeholderText =
    placeholder ||
    `Rows are separated by newlines
Key and value are separated by :
`;
  return (
    <Input
      id="exampleText"
      name="text"
      type="textarea"
      defaultValue={kvToText(defaultValue)}
      placeholder={placeholderText}
      onChange={(e) => onChange && onChange(textToKV(e.target.value))}
      {...props}
    />
  );
};

const HeaderTable = ({ headers }) => {
  let rows = [];

  for (const [key, value] of Object.entries(headers)) {
    rows.push(
      <tr key={`response-header-${key}`}>
        <td className="pl-3">{key}</td>
        <td className="text-secondary pl-3">{value}</td>
      </tr>
    );
  }

  return (
    <Table bordered size="sm" hover>
      <tbody>{rows}</tbody>
    </Table>
  );
};

export function InvocationPanel({ fn, namespace }) {
  const functionKey = `${fn}.${namespace}`;
  const invocationStore =
    JSON.parse(localStorage.getItem('invocation_store')) || {};
  const invocationState = invocationStore[functionKey] || {};

  const getDefaultBody = (invocationState) => {
    if (invocationState.body === null) {
      return null;
    }

    return invocationState.body || '';
  };

  const [functionRes, setFunctionRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState(invocationState.method);
  const [path, setPath] = useState(invocationState.path || '');
  const [headers, setHeaders] = useState(invocationState.headers || {});
  const [body, setBody] = useState(getDefaultBody(invocationState));
  const [parameters, setParameters] = useState(
    invocationState.parameters || {}
  );
  const [generatedHeaders, setGeneratedHeaders] = useState(
    invocationState.generatedHeaders || {}
  );
  const [downloadResponse, setDownloadResponse] = useState(
    invocationState.download
  );

  const mergeHeaders = (headers, generatedHeaders) => ({
    ...generatedHeaders,
    ...headers,
  });

  const invokeFunction = async () => {
    setLoading(true);

    let start = performance.now();
    let options = {
      method,
      namespace,
      functionName: fn,
      data: body,
      headers: mergeHeaders(headers, generatedHeaders),
      params: parameters,
      path,
    };
    if (downloadResponse) {
      options.responseType = 'blob';
    }

    try {
      let res = await functionsApi.invokeFunction(options);
      let end = performance.now();

      res.time = end - start;

      if (downloadResponse) {
        if (res.status >= 200 && res.status < 300) {
          res.objectURL = window.URL.createObjectURL(res.data);
          res.filename = crypto.randomUUID();
        } else {
          res.data = await res.data.text();
        }
      } else if (res.data instanceof Array || res.data instanceof Object) {
        res.data = JSON.stringify(res.data, null, 2);
      }

      setFunctionRes(res);
    } catch (err) {
      console.error('Failed to invoke function', err);
      setFunctionRes({ error: err });
    }

    setLoading(false);
  };

  useEffect(() => {
    let invocationState = {
      method,
      path,
      body,
      headers,
      parameters,
      generatedHeaders,
      download: downloadResponse,
    };
    invocationStore[functionKey] = invocationState;
    localStorage.setItem('invocation_store', JSON.stringify(invocationStore));
  }, [
    method,
    path,
    body,
    headers,
    parameters,
    generatedHeaders,
    downloadResponse,
  ]);

  return (
    <Card className={`invocation-panel height-100`}>
      <CardHeader
        tag="h6"
        className="flex align-items-center justify-content-space-between min-height-49"
      >
        <span>Invoke</span>
        <LogsButton to={`../../${fn}/logs/`}></LogsButton>
      </CardHeader>
      <CardBody>
        <Form>
          <div className="flex flex-col">
            <InputGroup>
              <MethodDropDown
                addonType="prepend"
                onSelect={setMethod}
                defaultValue={method}
              />
              <Input
                type="text"
                name="path"
                id="invoke-path"
                placeholder="/"
                onChange={(e) => setPath(e.target.value)}
                defaultValue={path}
              />
            </InputGroup>
            <Button className="ml-4" onClick={invokeFunction} color="primary">
              Send
            </Button>
          </div>
        </Form>
        <div className="mt-3">
          <RequestBuilder
            parameters={parameters}
            headers={headers}
            body={body}
            generatedHeaders={generatedHeaders}
            onBodyChange={setBody}
            onHeaderChange={setHeaders}
            onGeneratedHeaderChange={setGeneratedHeaders}
            onParameterChange={setParameters}
            downloadChecked={downloadResponse}
            onDownloadChecked={setDownloadResponse}
          />
        </div>
        <hr className="mt-1" />
        <div>
          {loading ? (
            <div className="text-center">
              <FontAwesomeIcon icon="spinner" spin />
            </div>
          ) : (
            <ResponsePanel functionRes={functionRes} />
          )}
        </div>
      </CardBody>
    </Card>
  );
}

const MethodDropDown = ({ defaultValue, onSelect, addonType }) => {
  const methods = ['get', 'post', 'put', 'delete'];

  const [selected, setSelected] = useState(defaultValue || methods[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <InputGroupButtonDropdown
      addonType={addonType}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle caret>{selected.toUpperCase()}</DropdownToggle>
      <DropdownMenu>
        {methods.map((method) => (
          <DropdownItem
            onClick={() => {
              setSelected(method);
              onSelect && onSelect(method);
            }}
          >
            {method.toUpperCase()}
          </DropdownItem>
        ))}
      </DropdownMenu>{' '}
    </InputGroupButtonDropdown>
  );
};

const DownloadToggle = ({ checked, onChange }) => {
  const toggle = () => onChange(!checked);

  return (
    <FormGroup check>
      <Input
        id="downloadCheckbox"
        type="checkbox"
        checked={checked}
        onChange={toggle}
      />
      <Label check for="downloadCheckbox">
        Download{' '}
      </Label>
    </FormGroup>
  );
};

const ContentTypeDropdown = ({ types, defaultValue, onSelect }) => {
  const typeNames = Object.keys(types);

  const [selected, setSelected] = useState(defaultValue || typeNames[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getMediaType = (name) => types[name];

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        caret
        data-toggle="dropdown"
        tag="span"
        className="text-primary"
      >
        {selected}
      </DropdownToggle>
      <DropdownMenu>
        {types &&
          typeNames.length &&
          typeNames.map((type) => (
            <DropdownItem
              style={{ fontSize: '14px' }}
              onClick={() => {
                setSelected(type);
                onSelect && onSelect(getMediaType(type));
              }}
            >
              {type}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const BodyTypeRadio = ({ defaultValue, onSelect }) => {
  const [selected, setSelected] = useState(defaultValue || 'none');

  const onRadioSelected = (selected) => {
    setSelected(selected);
    onSelect && onSelect(selected);
  };

  return (
    <FormGroup tag="div" className="flex mb-0">
      <FormGroup check className="mr-3">
        <Label check>
          <Input
            type="radio"
            name="none"
            checked={selected === 'none'}
            onChange={() => onRadioSelected('none')}
          />
          none
        </Label>
      </FormGroup>
      <FormGroup check className="mr-3">
        <Label check>
          <Input
            type="radio"
            name="raw"
            checked={selected === 'raw'}
            onChange={() => onRadioSelected('raw')}
          />
          raw
        </Label>
      </FormGroup>
    </FormGroup>
  );
};

const BodyEditor = ({ value, type, contentType, onChange }) => {
  const editorOptions = {
    width: '100%',
    height: '100%',
    theme: 'tomorrow',
    readOnly: false,
    autoScrollEditorIntoView: true,
    onChange: onChange,

    wrapEnabled: true,
    showPrintMargin: false,
    editorProps: {
      $blockScrolling: true,
    },
  };

  if (type === 'none') {
    return <p className="text-center">This request does not have a body</p>;
  } else if (type === 'raw') {
    return (
      <AceEditor
        {...editorOptions}
        mode={getEditorMode(contentType)}
        value={value}
      />
    );
  } else {
    return <p>Unsupported body type</p>;
  }
};

const RequestBuilder = ({
  headers,
  generatedHeaders,
  parameters,
  body,
  onBodyChange,
  onHeaderChange,
  onGeneratedHeaderChange,
  onParameterChange,
  downloadChecked,
  onDownloadChecked,
}) => {
  const [activeTab, setActiveTab] = useState('body');
  const [bodyType, setBodyType] = useState(body === null ? 'none' : 'raw');
  const [contentType, setContentType] = useState(
    (generatedHeaders && generatedHeaders['Content-Type']) || 'text/plain'
  );

  const isActiveTab = (tabId) => activeTab == tabId;

  const contentTypes = {
    Text: 'text/plain',
    JSON: 'application/json',
  };

  // Update generatedHeaders when a different content type is selected
  useEffect(() => {
    onGeneratedHeaderChange &&
      onGeneratedHeaderChange({
        'Content-Type': contentType,
      });
  }, [contentType]);

  // Set body to null when the body type is changed to none
  useEffect(() => {
    if (bodyType === 'none') {
      onBodyChange(null);
    } else if (bodyType === 'raw' && !body) {
      onBodyChange('');
    }
  }, [bodyType]);

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={isActiveTab('body') ? 'active' : ''}
            onClick={() => setActiveTab('body')}
          >
            Body
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={isActiveTab('parameters') ? 'active' : ''}
            onClick={() => setActiveTab('parameters')}
          >
            Params
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={isActiveTab('headers') ? 'active' : ''}
            onClick={() => setActiveTab('headers')}
          >
            Headers
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="flex flex-column">
        <TabPane tabId="body">
          <div className="flex flex-row justify-content-between border-bottom px-3 py-2 mb-1">
            <div className="flex flex-row">
              <BodyTypeRadio defaultValue={bodyType} onSelect={setBodyType} />
              {bodyType === 'raw' && (
                <div>
                  <ContentTypeDropdown
                    defaultValue={Object.keys(contentTypes).find(
                      (key) => contentTypes[key] === contentType
                    )}
                    types={contentTypes}
                    onSelect={setContentType}
                  />
                </div>
              )}
            </div>
            <DownloadToggle
              checked={downloadChecked}
              onChange={onDownloadChecked}
            />
          </div>
          <div
            style={{ height: '150px', resize: 'vertical', overflow: 'auto' }}
          >
            <BodyEditor
              type={bodyType}
              contentType={contentType}
              value={body}
              onChange={onBodyChange}
            />
          </div>
        </TabPane>
        <TabPane tabId="parameters">
          <div className="flex flex-row px-3 py-2 mb-1">
            <div className="font-weight-bold">Query Params</div>
          </div>
          <div className="align-self-stretch">
            <KeyValueEditor
              style={{ minHeight: '150px' }}
              placeholder={`Specify additional parameters as follows:

action: edit
verbose: 1`}
              defaultValue={parameters}
              onChange={onParameterChange}
            />
          </div>
        </TabPane>
        <TabPane tabId="headers">
          <div className="flex flex-row px-3 py-2 mb-1">
            <div className="font-weight-bold">Headers</div>
          </div>
          <KeyValueEditor
            style={{ minHeight: '150px' }}
            placeholder={`Specify additional headers as follows:

Content-Type: text/plain
X-Custom-Header: 123`}
            defaultValue={headers}
            onChange={onHeaderChange}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

const ResponsePanel = ({ functionRes }) => {
  const [activeTab, setActiveTab] = useState('body');

  const isActiveTab = (tabId) => activeTab == tabId;

  const downloadResponse = (url, filename) => {
    let linkElement = window.document.createElement('a');
    linkElement.href = url;
    linkElement.download = filename;
    linkElement.click();
  };

  useEffect(() => {
    if (functionRes && functionRes.objectURL) {
      downloadResponse(functionRes.objectURL, functionRes.filename);
    }
  }, [functionRes]);

  const editorOptions = {
    width: '100%',
    height: '100%',
    theme: 'tomorrow',
    readOnly: true,
    autoScrollEditorIntoView: true,

    wrapEnabled: true,
    showPrintMargin: false,
    editorProps: {
      $blockScrolling: true,
    },
  };

  if (!functionRes) {
    return (
      <div style={{ textAlign: 'center' }}>Hit send to get a response</div>
    );
  } else if (functionRes.error) {
    return (
      <div className="text-danger" style={{ textAlign: 'center' }}>
        Something went wrong. Hit send again to retry.
      </div>
    );
  } else {
    return (
      <div>
        <ResponseInfo
          statusCode={functionRes.status}
          statusText={functionRes.statusText}
          duration={functionRes.time}
        />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={isActiveTab('body') ? 'active' : ''}
              onClick={() => setActiveTab('body')}
            >
              Body
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={isActiveTab('headers') ? 'active' : ''}
              onClick={() => setActiveTab('headers')}
            >
              Headers
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="body">
            {functionRes.objectURL ? (
              <div className="px-3 py-2" style={{ textAlign: 'center' }}>
                <a download={functionRes.filename} href={functionRes.objectURL}>
                  Download response body
                </a>
              </div>
            ) : (
              <div
                style={{
                  height: '300px',
                  resize: 'vertical',
                  overflow: 'auto',
                }}
              >
                <AceEditor
                  {...editorOptions}
                  value={functionRes.data}
                  mode={getEditorMode(functionRes.headers['content-type'])}
                />
              </div>
            )}
          </TabPane>
          <TabPane tabId="headers">
            <div className="flex flex-row px-3 py-2">
              <div className="font-weight-bold">Headers</div>
            </div>
            <div
              style={{ height: '300px', resize: 'vertical', overflow: 'auto' }}
            >
              <HeaderTable headers={functionRes.headers} />
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
};

function textToKV(text) {
  let kv = {};

  let lines = text.split('\n');
  lines.forEach((line) => {
    if (line.length > 0) {
      let pair = line.split(':');
      let key = pair[0];
      let value = pair[1];

      if (key && value) {
        kv[key.trim()] = value.trim();
      }
    }
  });

  return kv;
}

function kvToText(kv) {
  let text = '';

  for (const [key, value] of Object.entries(kv)) {
    text += `${key}:${value}\n`;
  }

  return text;
}
