import React, { Component } from 'react';
import {
  NavLink as NavLinkRouter,
  withRouter,
  matchPath,
} from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const StyledNavLink = ({
  tag,
  path,
  label,
  additionalClassName,
  icon = null,
  onClick = () => {},
}) => {
  if (!path) {
    return null;
  }

  const to = `/${path}`;

  const className = ['py-3', 'px-3', 'px-md-2', additionalClassName]
    .filter((item) => item)
    .join(' ');

  return (
    <NavItem>
      {tag === 'a' ? (
        <NavLink className={className} tag={tag} href={to}>
          {icon}
          {label}
        </NavLink>
      ) : (
        <NavLink
          className={className}
          tag={tag}
          to={to}
          href={to}
          exact
          onClick={onClick}
        >
          {icon}
          {label}
        </NavLink>
      )}
    </NavItem>
  );
};

class NavBarWithRouter extends Component {
  state = {
    isActive: false,
  };

  toggle() {
    this.setState({
      isActive: !this.state.isActive,
    });
  }

  toggle = this.toggle.bind(this);

  isLoggedIn() {
    return cookies.get('openfaas_dashboard');
  }

  render() {
    const { pathname } = this.props.history.location;

    const match = matchPath(pathname, {
      path: '/functions/:namespace',
      strict: false,
    });
    // let namespace;
    // if (match && match.params) {
    //   namespace = match.params.namespace;
    // }

    return (
      <Navbar
        dark
        color="openfaas"
        expand="md"
        className={[
          'background-openfaas-important',
          'border-radius-bottom-5',
          'navbar-inverse',
          'p-0',
          'overflow-hidden',
        ].join(' ')}
      >
        <NavbarBrand
          href="/"
          className="font-size-0-important margin-0-important p-0 pl-3 pr-4"
        >
          <img alt="OpenFaaS Logo" src="/public/logo-text.svg" />
        </NavbarBrand>
        <NavbarToggler className="mr-2" onClick={this.toggle} />
        <Collapse isOpen={this.state.isActive} navbar>
          <Nav navbar>
            <StyledNavLink
              path={'functions'}
              label={'Home'}
              tag={NavLinkRouter}
            />
          </Nav>
          <Nav navbar className="ml-auto mr-2">
            {this.isLoggedIn() && (
              <StyledNavLink
                path={'auth/logout'}
                label={'Sign out'}
                tag={'a'}
                icon={<FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />}
              />
            )}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

const NavBar = withRouter(NavBarWithRouter);

export { NavBar };
