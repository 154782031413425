import axios from 'axios';
import moment from 'moment';

import { isAuthenticated } from '../lib/auth';

export const AutoscalingLabels = {
  Max: 'com.openfaas.scale.max',
  Min: 'com.openfaas.scale.min',
  Target: 'com.openfaas.scale.target',
  Type: 'com.openfaas.scale.type',
  Proportion: 'com.openfaas.scale.target-proportion',
  Zero: 'com.openfaas.scale.zero',
  ZeroDuration: 'com.openfaas.scale.zero-duration',
};

const autoscalingDefaults = {
  max: 20,
  min: 1,
  target: 50,
  type: 'rps',
  proportion: 0.9,
  zero: false,
  zeroDuration: '15m',
};

const getRepoURL = (annotations) =>
  annotations['com.openfaas.git-repo-url'] || '';

class FunctionsApi {
  constructor() {
    this.prettyDomain = window.PRETTY_URL;
    this.queryPrettyUrl = window.QUERY_PRETTY_URL === 'true';

    if (process.env.NODE_ENV === 'production') {
      this.baseURL = window.PUBLIC_URL;
      this.apiBaseUrl = `${window.BASE_HREF}api`;
    } else {
      this.baseURL = 'http://127.0.0.1:3000';
      this.apiBaseUrl = '/api';
    }

    this.cachedFunctions = {};
    this.cachedNamespaces = {};
  }

  parseFunctionResponse({ data }) {
    data = data || [];

    data.sort((a, b) => {
      if (!a || !b || !a.createdAt || !b.createdAt) {
        return 0;
      }
      return new Date(b.createdAt, 10) - new Date(a.createdAt, 10);
    });

    return data.map((item) => {
      const since = new Date(item.createdAt);
      const sinceDuration = moment(since).fromNow();

      let labels = item.labels || {};

      let shortSha = labels['com.openfaas.git-sha'];
      if (shortSha) {
        shortSha = shortSha.substr(0, 7);
      } else {
        shortSha = '';
      }

      let isPrivate = false;

      if (
        labels['com.openfaas.git-private'] &&
        labels['com.openfaas.git-private'] === '1'
      ) {
        isPrivate = true;
      }

      return {
        name: item.name,
        image: item.image,
        createdAt: item.createdAt,
        namespace: item.namespace,
        shortSha,
        sinceDuration,
        invocationCount: item.invocationCount,
        replicas: item.replicas,
        gitRepo: labels['com.openfaas.git-repo'],
        gitOwner: labels['com.openfaas.git-owner'],
        gitPrivate: isPrivate,
        gitSha: labels['com.openfaas.git-sha'],
        gitBranch: labels['com.openfaas.git-branch'],
        gitRepoURL: getRepoURL(item.annotations || {}),
        scale: getScalingMeta(labels),
      };
    });
  }

  async deleteFunction(namespace, functionName) {
    const url = `${this.apiBaseUrl}/delete-function?namespace=${namespace}&function=${functionName}`
    return axios.delete(url)
  }

  fetchNamespaces() {
    if (!isAuthenticated()) {
      window.location.reload();
      throw Error('Auth session is expired');
    }

    const url = `${this.apiBaseUrl}/list-namespaces`;
    return axios.get(url).then((res) => {
      const { data } = res;
      let namespaces = data.sort();
      this.cachedNamespaces = namespaces;
      return namespaces;
    });
  }

  fetchFunctions(namespace) {
    if (!isAuthenticated()) {
      window.location.reload();
      return Promise.reject(new Error('Auth session is expired'));
    }

    let fetchPromises = [];
    fetchPromises.push(this.fetchFunctionsByNamespace(namespace));

    return Promise.all(fetchPromises);
  }

  fetchFunctionsByNamespace(namespace) {
    const url = `${this.apiBaseUrl}/list-functions?namespace=${namespace}`;
    return axios
      .get(url)
      .then((res) => this.parseFunctionResponse(res))
      .then((data) => {
        this.cachedFunctions = data.reduce((cache, fn) => {
          cache[`${fn.namespace}/${fn.name}`] = fn;

          return cache;
        }, {});
        return data;
      });
  }

  fetchFunction(namespace, fnName) {
    return new Promise((resolve, reject) => {
      if (!isAuthenticated()) {
        window.location.reload();
        reject(new Error('Auth session is expired'));
      }

      const key = `${namespace}/${fnName}`;

      const cachedFn = this.cachedFunctions[key];
      if (cachedFn) {
        resolve(cachedFn);
        return;
      }

      // fetch functions if cache not found
      this.fetchFunctions(namespace).then((res) => {
        const fn = this.cachedFunctions[key];
        fn !== undefined
          ? resolve(fn)
          : reject(new Error(`Function ${key} not found`));
      });
    });
  }

  async fetchFunctionDetails(params) {
    if (!isAuthenticated()) {
      window.location.reload();
      throw Error('Auth session is expired');
    }

    const { namespace, functionName } = params;

    const url = `${
      this.apiBaseUrl
    }/query-function?namespace=${namespace.toLowerCase()}&function=${functionName}`;
    const result = await axios.get(url);
    return result.data;
  }

  async fetchFunctionInvocation(params) {
    if (!isAuthenticated()) {
      window.location.reload();
      throw Error('Auth session is expired');
    }

    const { namespace, functionName, timePeriod } = params;

    const url = `${
      this.apiBaseUrl
    }/metrics?namespace=${namespace.toLowerCase()}&function=${functionName}&metrics_window=${timePeriod}`;
    const result = await axios.get(url);
    return result.data;
  }

  fetchFunctionLog({ namespace, functionName, since }) {
    if (!isAuthenticated()) {
      window.location.reload();
      throw Error('Auth session is expired');
    }

    const url = `${
      this.apiBaseUrl
    }/function-logs?namespace=${namespace}&function=${functionName}&since=${encodeURIComponent(
      since
    )}`;

    return axios
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((fail) => {
        console.error('logs', fail);
        throw Error(
          'Failed to fetch function logs - is the function scaled to 0? \nmessage:' +
            fail.message
        );
      });
  }

  async invokeFunction({
    method,
    namespace,
    functionName,
    headers,
    params,
    path,
    data,
    responseType,
  }) {
    if (!isAuthenticated()) {
      window.location.reload();
      throw Error('Auth session is expired');
    }

    const functionPath = `${this.apiBaseUrl}/invoke/${functionName}.${namespace}`;
    const p = new URL(path, 'http://127.0.0.1');
    const url = `${functionPath}${p.pathname === '/' ? '' : p.pathname}`;

    let res = await axios({
      method,
      url,
      headers,
      data,
      params,
      responseType,
      validateStatus: () => true,
    });

    return res;
  }
}

export const buildPublicFunctionURL = (url, newEnding) => {
  if (url.endsWith('/')) {
    url = url.substr(0, url.length - 1);
  }
  if (!url.endsWith('function')) {
    return url;
  }
  return url.substr(0, url.length - 'function'.length) + newEnding;
};

function getScalingMeta(labels) {
  let max = Number.parseInt(labels[AutoscalingLabels.Max]);
  let min = Number.parseInt(labels[AutoscalingLabels.Min]);
  let proportion = Number.parseFloat(labels[AutoscalingLabels.Proportion]);

  let scalingMeta = {
    max: max || autoscalingDefaults.max,
    min: min || autoscalingDefaults.min,
    target: labels[AutoscalingLabels.Target] || autoscalingDefaults.target,
    type: labels[AutoscalingLabels.Type] || autoscalingDefaults.type,
    proportion: proportion || autoscalingDefaults.proportion,
    zero: labels[AutoscalingLabels.Zero],
    zeroDuration:
      labels[AutoscalingLabels.ZeroDuration] ||
      autoscalingDefaults.zeroDuration,
  };

  if (scalingMeta.min === scalingMeta.max || scalingMeta.proportion === 0)
    scalingMeta.type = 'disabled';

  return scalingMeta;
}

export const functionsApi = new FunctionsApi();
